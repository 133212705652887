
const AccountDeletePage = () => {
    const userDelete = () => {
        alert("Telefonunuza bir kod gönderildi, lütfen oradaki talimatları takip ediniz.")
    }


    return(<div className={"p-1"} style={{justifyContent:"center", alignItems:"center", width:"100%"}}>
        <p>Verilerinizin ve kullanıcınızın silinmesi için lütfen telefon numarası giriniz.</p>
        <p>Kullanıcı Telefon Numarası</p>
        <input placeholder={"(555) 555 55 55"}></input>
        <button onClick={() => userDelete()}>Gönder</button>
    </div>)
}

export default  AccountDeletePage