import React from 'react';

import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import PrivacyPolicyPage from "./pages/Policy/PrivacyPolicyPage";
import KvkkPage from "./pages/Policy/KvkkPage";
import TermsOfUsePage from "./pages/Policy/TermsOfUsePage";
import AccountDeletePage from "./pages/Account/AccountDeletePage";


export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path={"/"} element={<HomePage></HomePage>}></Route>
            <Route path={"/privacy-policy"} element={<PrivacyPolicyPage></PrivacyPolicyPage>}></Route>
            <Route path={"/kvkk"} element={<KvkkPage></KvkkPage>}></Route>
            <Route path={"/terms-of-use"} element={<TermsOfUsePage></TermsOfUsePage>}></Route>
            <Route path={"/account/delete"} element={<AccountDeletePage></AccountDeletePage>}></Route>
        </Route>
    )
)

