import Lottie from "lottie-react";
import Background from "../../assets/images/background.json";
import Logo from "../../assets/images/logo-white.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAppStoreIos, faGooglePlay} from "@fortawesome/free-brands-svg-icons";
import mobileAppImage from "../../assets/images/app-image.png";
import React from "react";
import {Link} from "react-router-dom";

const HomePage = () => {
    return (
        <div className={"app-container"}>

            <div style={{width: '100%', height: '100%', position: 'fixed', zIndex: -1, top: 0, left: 0}}>
                <Lottie loop={true} autoplay={true} animationData={Background}/>
            </div>

            <div className={"app-header"}>
                <div className={"app-header-logo"}>
                    <img src={Logo} alt="ORDU+ | ORDU'NUN UYGULAMASI"/>
                </div>
                <div className={"app-header-contact"}>
                    <div className={"app-header-contact-button"}>
                        Tanıtım Filmi
                    </div>
                </div>
            </div>

            <div className={"app-content"}>
                <div className={"content-mobile-app"}>
                    <div className={"content-mobile-app-text-1"}>
                        ORDU'NUN
                    </div>
                    <div className={"content-mobile-app-text-2"}>
                        Mobil Uygulaması
                    </div>

                    <div className={"mt-2"}>
                        <div className={"content-mobile-app-text-3 mt-1"}>
                            Ayrıcalığınızı
                        </div>
                        <div className={"content-mobile-app-text-4"}>
                            Onun ile keşfedeceksiniz
                        </div>
                    </div>

                    <div>
                        <div className={"mobile-app-store-content"}>
                            <div className={"mobile-app-store-content-detail"}>
                                <a href={"https://apps.apple.com/tr/app/ordu/id6471663462"}
                                   style={{backgroundColor: 'purple'}}
                                   className={"mobile-app-store-content-detail-item"}>
                                    <FontAwesomeIcon icon={faAppStoreIos}
                                                     style={{color: "white", width: 50, height: 50}}/>
                                    <div style={{color: "white"}}>App Store</div>
                                </a>

                                <a href={"https://play.google.com/store/apps/details?id=com.orduplusmobile"}
                                   style={{backgroundColor: 'purple'}}
                                   className={"mobile-app-store-content-detail-item"}>

                                    <FontAwesomeIcon icon={faGooglePlay}
                                                     style={{color: "white", width: 50, height: 50}}/>
                                    <div style={{color: "white"}}>Play Store</div>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>
                <div className={"contant-app-image"}>
                    <img src={mobileAppImage} alt={"ORDU+"}></img>
                </div>
            </div>

            <div className={"app-footer"}>
                <div className={"app-footer-project-name"}>
                    <Link to={"https://www.ordu.bel.tr"}>
                        <img src={"https://www.ordu.bel.tr/assets/images/head-logo.png"}
                             alt={"Ordu Büyükşehir Belediyesi"}></img>

                    </Link>
                    <div>Ordu Büyükşehir Belediyesi Projesidir</div>
                </div>
                <div>
                Tüm hakları saklıdır © 2024
                </div>

                <div className={"app-footer-policy"}>
                    <Link to={"/privacy-policy"} >Gizlilik Politikası</Link>
                    <Link to={"/kvkk"}>Kvkk Aydınlatma Metni</Link>
                    <Link to={"/terms-of-use"}>Kullanım Şartları</Link>
                </div>
            </div>
        </div>
    )
}

export default HomePage;