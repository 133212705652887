import axios from "axios";

export const API_URL =
    process.env.NODE_ENV === 'development'
        ? 'https://cdn.orduplus.com.tr/'
        : 'https://cdn.orduplus.com.tr/';

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {


        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {

        // const originalConfig = err.config;


        // if (originalConfig.url !== "/login" && err.response) {
        //     // Access Token was expired
        //     if (err.response.status === 401 && !originalConfig._retry) {
        //         originalConfig._retry = true;
        //         try {
        //             console.log("refreshToken:")
        //             console.log(TokenService.getLocalRefreshToken())
        //
        //             const rs = await instance.get("/api/Auth/RefreshToken?refreshToken="+TokenService.getLocalRefreshToken(), {
        //                 refreshToken: TokenService.getLocalRefreshToken(),
        //             });
        //
        //
        //             TokenService.setUser(rs.data.data)
        //             return instance(originalConfig);
        //         } catch (error) {
        //             // TokenService.removeUser()
        //             // window.location.assign('/')
        //             return Promise.reject(error);
        //         }
        //     }
        // }




        return Promise.reject(err);
    }
);

export default instance;
