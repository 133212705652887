import React, {useEffect, useState} from "react";
import Api from "../../services/Api";
import parse from 'html-react-parser';
import Lottie from "lottie-react";
import Background from "../../assets/images/background.json";
import {Card} from "antd";
import {Link} from "react-router-dom";

const KvkkPage = () => {

    const [loading,setLoading] = useState(false);
    const [kvkk,setKvkk] = useState();

    useEffect(() => {
        getPrivacyPolicy()
    }, []);

    const getPrivacyPolicy = () => {
        setLoading(true)
        Api.get("/api/MobileAppConfigures/GetByAllConfigure").then(res => {
            setKvkk(res.data.kvkkPolicy)
            setLoading(false)
        }).catch(err => {console.log(err)})
    }

    return (
        <div className={"app-container"}>
            <div style={{width: '100%', height: '100%', position: 'fixed', zIndex: -1, top: 0, left: 0}}>
                <Lottie loop={true} autoplay={true} animationData={Background}/>
            </div>
            <Card title={"ORDU+"} extra={<Link style={{color:"white"}} to={"/"}>Anasayfa</Link>} loading={loading}>
                {parse(kvkk ?? '')}
            </Card>
        </div>
    )
}
export default KvkkPage